import React from "react";
import Testimonial from "../components/Employers/Testimonial";
import HeroImages from "../components/Reusables/HeroImages";
import SEO from "../components/seo";
import Accordion from "../components/Reusables/Accordion";
import Layout from "../components/layout";
import styled from "styled-components";
import Testimonialtech500 from "../components/Employers/Tech500testimonial";
import ReactMarkdown from "react-markdown";
import axios from "axios";
import { useState, useEffect } from "react";
import { QueryClient, QueryClientProvider, useQuery } from "react-query";
import Loader from "../components/Reusables/Loader";
import SummaryBlock from "../components/Home/SummaryBlock";
import DescriptionBlock from "../components/Home/DescriptionBlock"
const StyledWrapper = styled.div`
  .apply-now {
    padding: 3% 0;
    background-color: #e43d5a;
  }

  .boxHeight {
    @media (max-width: 600px) {
      height: 250px;
    }
  }

  .accordion-start {
    padding-top: 50px;
  }

  .flat-button {
    color: white;
    font-size: 32px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 43px;
    margin: auto;
    @media (max-width: 600px) {
      font-size: 22px;
    }
  }

  .flat-btn-link {
    margin: auto;
    display: block;
    text-decoration: none;
    text-align: center;
  }

  .flat-btn-link:hover {
    color: white;
  }

  .section-divider {
    margin-top: 50px;
  }

  .margin-top {
    margin-top: 80px;
  }

  .grew-up {
    padding: 3% 0;
    background-color: #5db9db;
    height: 160px;

    @media (max-width: 600px) {
      height: unset;
      padding: 5% 8%;
    }
  }

  .onhover-display {
    color: white;
    font-size: 25px;
    font-weight: 800;
    letter-spacing: 0;
    line-height: 32px;
    text-align: center;
    display: none;
    @media (max-width: 600px) {
      font-size: 14px;
      line-height: normal;
    }
  }

  .grew-up:hover {
    padding-top: 2%;
  }

  .grew-up:hover .onhover-display {
    display: block;
  }

  .grew-up:hover .flat-button {
    display: none;

    @media (max-width: 600px) {
      display: unset;
    }
  }

  .onhover-links {
    color: white;
  }
  .onhover-links:hover {
    color: white;
  }

  .subtext a {
    text-decoration: underline;
    color: black;
  }

  .subtext {
    font-size: 20px;
  }
  .test {
    width: 100%;
  }
`;
const queryClient = new QueryClient();

export default function App() {
  return (
    <QueryClientProvider client={queryClient}>
      <TechPage />
    </QueryClientProvider>
  );
}

const fetchData = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/tech-500`);
};
const fetchHeroImage = () => {
  return axios.get(`${process.env.GATSBY_API_URL}/hero-images`);
};

const TechPage = () => {
  const [newData, setNewData] = useState(null);
  const [heroImages, setHeroImages] = useState(null);
  const { data: pageData } = useQuery("tech500", fetchData);
  const { data: heroImage } = useQuery("tech500Images", fetchHeroImage);
  useEffect(() => {
    if (pageData !== undefined) {
      setNewData(pageData.data);
    }
    if (heroImage !== undefined) {
      setHeroImages(heroImage.data);
    }
  }, [pageData, heroImage]);
  return (
    <Layout>
      <StyledWrapper>
        {newData === null && <Loader />}
        <SEO title="Tech 500" description={newData && newData.SEOdescription}/>
        {newData &&
          newData.showheroImage &&
          heroImages &&
          heroImages.length > 0 &&
          heroImages.map(
            item =>
              item.Slug.toLowerCase() === "tech500".toLowerCase() &&
              item.Image && <HeroImages heroImage={item.Image.url} />
          )}
        {newData &&
          newData.showTestimonial &&
          newData.Testimonial.length > 0 && (
            <Testimonial carousel={newData.Testimonial} />
          )}
        <div className="container-fluid p-0">
          <div className="padding-left-8 padding-right-8 accordion-start">
            {/* <h1>{pageData.SponsorTitle}</h1>
                        <p>{pageData.SponsorSubHeading}</p>
                        <img src={pageData.SponsorImage.publicURL} alt="diversitylogo"></img> */}
            {newData && newData.showDescription && (
              // <ReactMarkdown
              //   className="pt-5 subtext"
              //   source={newData.SponsorDescription}
              // />
              <div className="pt-5 subtext">
              <DescriptionBlock description={newData.SponsorDescription}/>
              </div>
            )}
          </div>

          {newData && newData.showAccordion && (
            <Accordion
              accordionHeading={newData.AccordionHeading}
              accordion={newData.Accordion}
            />
          )}
          {newData &&
            newData.showtech500Carousel &&
            newData.tech500Carousel.length > 0 && (
              <Testimonialtech500 carousel={newData.tech500Carousel} />
            )}
        </div>
      </StyledWrapper>
    </Layout>
  );
};
